@import '../../../../sass/main.scss';

.ck-footer {
    @extend .ck-flex-x-center;
    position: relative;
    min-height: 400px;
    padding: 60px 30px 40px 30px;
    box-sizing: border-box;
    background-color: $color-second;

    .ck-footer-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
    .ck-footer-body {
        @extend .ck-flex-x-wrap;
        gap: 30px;
        position: relative;
        width: 100%;
        max-width: $content-max-width;

        .ck-footer-column {
            @extend .ck-flex-y;
            flex: 1;
            min-width: 280px;
            height: 100%;
            gap: 20px;
            a { 
                color: $color-white; 
                transition: color 0.2s 0s;
                font-size: 16px;
                text-decoration: none;
                transition: color 0.2s 0s;
                &:hover { color: $color-main; }
            }
            p.ck-footer-column-title {
                margin: 10px 0 0 0 ;
                font-size: 22px;
                color: rgb($color-main, 100%);
                text-transform: uppercase;
            }
            ul.ck-footer-column-items {
                @extend .ck-flex-y-start;
                padding: 0;
                margin: 0;

                li { 
                    @extend .ck-flex-x-start-center;
                    gap: 15px;
                    margin: 10px 0;
                    list-style-type: none;
                    color: $color-white; 
                    font-size: 16px;
                    
                    a { color: $color-white; font-size: 16px; }
                    &.ck-enter { a { color: $color-main; } }
                    &.ck-press { @extend .ck-animate-press-button-default; }
                }
            }
        }
    }
    .ck-footer-copyright {
        @extend .ck-flex-y-center;
        position: relative;
        font-size: 14px;
        padding: 20px 0;
        width: 100%;
        
        .copyright-info {
          position: relative;
          display: inline-block;
          padding: 0;
          font-size: 14px;
          color: $color-white; 
          a {
            transition: color 0.2s 0s;
            margin: 0 5px 0 0;
            color: $color-main;
            &:hover { color: lighten($color-main, 15%); }
          }
        }

    }
    .ck-footer-button-scroll-up {
        position: absolute;
        top: -30px;
        right: 30px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $color-second;
        transition: background-color 0.3s 0s;
        box-shadow:  0px 6px 15px 0px rgb($color-black, 25%);
        opacity: 0;

        svg * { fill: $color-white; }
        &.ck-press { @extend .ck-animate-press-button-default-a; }
        &.ck-enter { background-color: lighten($color-second, 3%); }
        &.ck-scroll-in-y { animation: ckAnimateFooterButtonScrollUpFadeIn 0.42s 0.05s 1 forwards; }
        &.ck-scroll-out-y { opacity: 0; }
    }
}

@keyframes ckAnimateFooterButtonScrollUpFadeIn {
    0% { opacity: 0; transform: translateY(30px) scale(0.8); }
    40% { opacity: 1; transform: translateY(-2px) scale(1.04); }
    55% { opacity: 1; transform: translateY(3px) scale(0.99); }
    80% { opacity: 1; }
    100% { opacity: 1; transform: translateY(0) scale(1); }
}

@media screen and (max-width: 1360px) { 
    .ck-footer {
      .ck-footer-content {
        .ck-footer-column:nth-child(1) { order: 4; }
        .ck-footer-column:nth-child(2) { order: 1; }
        .ck-footer-column:nth-child(3) { order: 2; }
      }
    }
  }
  
  @media screen and (max-width: 980px) { 
    .ck-footer {
      .ck-footer-content {
        .ck-footer-column:nth-child(1) {
          margin: 60px 0 0 0;
          order: 4;
          .ck-footer-logo { display: none; }
          .ck-footer-copyright { 
            text-align: center;
            width: 100% 
          };
        }
        .ck-footer-column:nth-child(1) { min-width: 50%; }
        .ck-footer-column:nth-child(2) { min-width: 50%; }
      }
    }
  }