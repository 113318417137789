@import '../../../../sass/main.scss';

.ck-expander {
    @extend .ck-flex-y-start;
    min-width: 240px;
    min-height: 150px;
    box-sizing: border-box;
    gap: 15px;
    transition: box-shadow 0.3s 0s;
      
    .ck-expander-label-container {
        @extend .ck-flex-x-start;
        position: relative;
        align-items: stretch;
        width: 100%;
        min-height: 24px;

        .ck-expander-label-ico {
            position: relative;
            width: 24px;
            margin: 0 5px 0 0;
            border-radius: 12px;

            svg {
                * { fill: $color-main; }
            }
         }
         .ck-expander-label {
            flex: 1;
            font-size: 24px;
            padding: 0 10px 0 0;
            box-sizing: border-box;
            color: $color-black;
            cursor: pointer;
            span { display: inline-block; }
         }
         .ck-button-ico.ck-expander-expand-ico {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: rgb($color-main, 5%);

            svg {
                * { fill: $color-main; }
            }
         }
    }
    .ck-expander-info-container {
        @extend .ck-flex-y-start;
        position: relative;
        height: 0;
        max-height: 320px;
        padding: 0 10px 0 0 ;
        box-sizing: border-box;
        overflow: auto;

        .ck-expander-info {
            position: relative;
            font-size: 17px;
            color: $color-black;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        .ck-clipper-gradual { 
            @extend .ck-animate-display-on; 
            background: -moz-linear-gradient(0deg, $color-white 0%, rgb($color-white, 0) 100%);
            background: -webkit-linear-gradient(0deg, $color-white 0%, rgb($color-white, 0) 100%);
            background: linear-gradient(0deg,$color-white 0%, rgb($color-white, 0) 100%);
        }
    }

    &.ck-expanded {
        .ck-expander-info-container { 
            height: auto; 
        }
    }   
}

.ck-expander.ck-mod-a {
    .ck-expander-info-container {
        height: 40px;
        overflow: hidden;

        .ck-clipper-gradual { @extend .ck-animate-display-on; }
    }
    &.ck-expanded {
        .ck-expander-info-container {
            height: auto;
            opacity: 1;
            overflow: auto;
            .ck-clipper-gradual { @extend .ck-animate-display-off; }
        }
    }
}

.ck-expander.ck-mod-b {
    padding: 20px;
    border-radius: 30px;
    width: 100%;
    background-color: rgb($color-grey, 4%);

    &.ck-expanded {
        .ck-expander-info-container {
            display: block;
        }
    }
}

.ck-expander.ck-mod-c {
    padding: 20px;
    border-radius: 30px;
    width: 100%;
    background-color: mix($color-white, $color-grey, 96%);
    
    .ck-expander-info-container {
        height: 40px;
        overflow: hidden;

        .ck-clipper-gradual { 
            background: -moz-linear-gradient(0deg, mix($color-white, $color-grey, 96%) 0%, transparent 100%);
            background: -webkit-linear-gradient(0deg, mix($color-white, $color-grey, 96%) 0%, transparent 100%);
            background: linear-gradient(0deg, mix($color-white, $color-grey, 96%) 0%, transparent 100%);
        }
    }
    &.ck-collapsed {
        .ck-expander-info-container {
            .ck-clipper-gradual { 
                animation: ckAnimateExpanderFadeIn 0.3s 0s 1 forwards;
            }
        }
    }
    &.ck-expanded {
        align-self: stretch;
        .ck-expander-info-container {
            height: auto;
            opacity: 1;
            overflow: auto;
            .ck-clipper-gradual {
                animation: ckAnimateExpanderFadeOut 0.3s 0s 1 forwards;
            }
        }
    }
    &:hover {
        box-shadow:  0px 4px 15px 0px rgb($color-black, 10%);
    } 
}

@keyframes ckAnimateExpanderFadeIn {
    0% {opacity: 0; }
    100% {opacity: 1; }
}
@keyframes ckAnimateExpanderFadeOut {
    0% {opacity: 1; }
    100% {opacity: 0; }
}


@media screen and (max-width: 800px) { 
    .ck-expander { 
        min-height: 100px;
    }
}
@media screen and (max-width: 500px) { 
    .ck-expander { 
        .ck-expander-label-ico { display: none };
    }
}
