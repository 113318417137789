@import '../../../../sass/main.scss';

.ck-info-card {
   @extend .ck-flex-y-start;
   position: relative;
   gap: 30px;

   * { @extend .ck-text-size-normal; }
   .ck-info-card-title { 
     position: relative; 
     font-weight: 500;
     color: $color-main;
   }
   .ck-info-card-items {
      @extend .ck-flex-y-start;
      gap: 20px;

      .ck-info-card-item {
        @extend .ck-flex-x;
        gap: 20px;
        
        div:nth-child(1) {
           font-weight: 500;
        }
        div:nth-child(2) {
           font-weight: 400;
        }
      }
   }
}
