@keyframes animateBlink {
  0% { opacity: 1; }
  10% { opacity: 0.2; }
  30% { opacity: 0.7; }
  100% { opacity: 1; }
}

@keyframes ckAnimatePressButton {
  0% { opacity: 1; }
  10% { opacity: 0.2; }
  30% { opacity: 0.7; }
  100% { opacity: 1; }
}

@keyframes ckAnimatePressButtonA {
  0% { opacity: 1; transform: scale(1.3); }
  20% { opacity: 0.2; transform: scale(0.7);}
  100% { opacity: 1; transform: scale(1);}
}

@keyframes ckAnimateUnderlineDefault {
  0% { opacity: 0.2;  transform: scaleX(0); }
  18% { transform: scaleX(1); }
  30% { opacity: 0.7; transform: scaleX(0.92); }
  100% { opacity: 1; transform: scaleX(1) }
}

@keyframes ckAnimateFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes ckAnimateFadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes ckAnimateVisibilityOn {
  0% { opacity: 0; visibility: visible; }
  100% { opacity: 1; visibility: visible;}
}
@keyframes ckAnimateVisibilityOff {
  0% { opacity: 1; visibility: visible;}
  99.9% { opacity: 0; visibility: visible;}
  100% { opacity: 0; visibility: hidden; }
}

@keyframes ckAnimateFadePosInX {
  0% { opacity: 0; left: 0; }
  100% { opacity: 1; left: 0; }
}
@keyframes ckAnimateFadePosOutX {
  0% { opacity: 1; }
  99.9% { opacity: 0; left: 0; }
  100% { opacity: 0; left: 10000px; }
}

@keyframes ckAnimateDisplayOn {
  0% { opacity: 0; display: block;}
  100% { opacity: 1; display: block; }
}
@keyframes ckAnimateDisplayOff {
  0% { opacity: 1; display: block; }
  99.9% { opacity: 0; display: block; }
  100% { opacity: 0; display: none; }
}

@keyframes ckAnimatePressFieldInput {
  0% { background-color: rgb(desaturate($color-main, 38%), 30%); }
  30% { background-color: rgb(desaturate($color-main, 38%), 15%); }
  100% { background-color: rgb(desaturate($color-main, 38%), 8%); }
}
@keyframes ckAnimateScaleIn {
  0% { transform: scale(0.5); }
  30% { transform: scale(1);}
  40% { transform: scale(0.99);}
  100% {transform: scale(1);}
}

@keyframes ckAnimateScaleOut {
  0% { transform: scale(0.98); }
  20% { transform: scale(1); }
  90% {transform: scale(0.8);}
  100% {transform: scale(0.8);}
}

@keyframes ckAnimateSectionFadeInY {
  0% { opacity: 0; transform: translateY(10%); }
  100% { opacity: 1; transform: translateY(0); }
}