@import '../../sass';

.ck-page-title-container {
    padding: 100px 20px 60px 20px;
    height: 340px;
    .ck-section-background {
        div {
            width: 100%;
            height: 100%;
            background: -moz-linear-gradient(180deg, $color-second 20%, transparent 100%);
            background: -webkit-linear-gradient(180deg, $color-second 20%, transparent 100%);
            background: linear-gradient(180deg, $color-second 20%, transparent 100%);
        }
    }
    .ck-section-content {
        @extend .ck-flex-y-start-center;
        height: 100%;
    }
    .ck-page-title {
        .ck-page-title-text {
            position: relative;
            text-transform: uppercase;
            font-size: 38px;
            font-weight: 500;
            margin: 0;
            color: $color-white;
        }
        .ck-label {
            margin: 5px 0 0 0;
            .ck-label-ico {
                div:nth-child(1) { 
                    width: 24px;
                    height: 100%;
                    border-radius: 0;
                    background-color: rgb($color-main, 40%);
                }
                svg * { fill: $color-white; }
            }
        }
    }

    .ck-section-background .ck-page-tittle-background { 
        position:absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        min-height: 100%; 
        background: url('./images/background.jpg') no-repeat center top; 
        overflow: hidden;
    
        div {
          position: absolute; 
          top: 0; 
          left: 0; 
          width: 100%;
    
          &:nth-child(1) {
            opacity: 0.5; 
            height: 100%; 
            transform: scale(1.02); 
            background: url('./images/background.jpg') no-repeat center top; 
            clip-path: url(#greetingBackgroundMask);
          }
          &:nth-child(2) {
            height: 120px; 
            opacity: 0; 
            background: url('./images/line.png') no-repeat center; 
            animation: greetingBackgroundAnimateA 6s 0s infinite;
          } 
        } 
        svg { 
          position: absolute; 
          top: 0; 
          left: 0; 
          width: 100%; 
          height: 100%;
          opacity: 0;
          rect { 
            animation: greetingBackgroundAnimateB 6s 0s infinite; 
          }
        }
      }
      @keyframes greetingBackgroundAnimateA { 
        0% { top: 70%; opacity: 0; transform: translateY(0); }
        4.99% { top: 70%; opacity: 0; transform: translateY(0); }
        5% { opacity: 0.2; transform: translateY(0); }
        6% { opacity: 0.5; transform: translateY(40%); }
        7% { opacity: 0.2; transform: translateY(0); }
        10% { top: 80%; opacity: 0; } 
        10.01% { top: 50%; opacity: 0.5; } 
        10.05% { opacity: 0; } 
        11% { opacity: 0; } 
        12% { top: 20%; opacity: 0.1; } 
        13% { top: 22%; opacity: 0.1; } 
        13.05% { opacity: 0; }
        14% { top: 60%; opacity: 0.4; } 
        30% { transform: translateY(0); } 
        31% { transform: translateY(50%); } 
        32% { transform: translateY(0); } 
        33% { transform: translateY(50%); } 
        34% { transform: translateY(0); } 
        40% { top: 0; opacity: 0; }
        100% { top: 0; opacity: 0; }
      }
      @keyframes greetingBackgroundAnimateB {
        0% { transform: translateY(-20%) scaleY(0.2); } 

        15.5% { transform: translateY(50%); }
        28% { transform: translateY(90%) scaleY(1); } 
        40% { transform: translateY(100%) scaleY(1); }  
        60% { transform: translateY(100%) scaleY(1); } 
        60.01% { transform: translateY(100%) scaleY(1); } 
        100% { transform: translateY(0) scaleY(0); }
      }
}