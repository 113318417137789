@import '../../sass';

.ck-contacts {
    .ck-section-content {
        @extend .ck-flex-y-start;
        gap: 80px;

        .ck-contact-cards {
            @extend .ck-flex-x-start;
            flex-wrap: wrap;
            gap: 30px;
            
            .ck-info-card { 
                flex: 1; 
                min-width: 280px;
                .ck-info-card-item {
                    div:nth-child(1){ min-width: 100px; }
                }
            }
            a {
                text-decoration: none;
                color: $color-black;
            }
        }
        .ck-contacts-address-map {
            width: 100%;
            height: 440px;
            overflow: hidden;
            border-radius: 10px;
            box-shadow:  0px 6px 15px 0px rgb($color-black, 15%);
        }
    }
}

