@import '../../../../../sass/main.scss';

.ck-button-ico-wrapper { 
  @extend .ck-flex-x-center;
  min-width: 120px;
  min-height: 44px;

  .ck-info { color: $color-grey; }
}

.ck-button-ico {
  @extend .ck-flex-x-center;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;

  .ck-ico {
    @extend .ck-flex-x-center;
    width: 100%;
    height: 100%;
  }

  div {
    background-position: center;
    background-repeat: no-repeat;
  }
  .ck-ico { 
    svg {
      width: 24px;
      height: 24px;
      * { fill: $color-white; }
    }
  }
  &.ck-press { @extend .ck-animate-press-button-default; }
}


