@import '../../sass';

$color-background: mix($color-white, $color-grey, 96%);
.ck-product-list {
    @extend .ck-flex-x;
    flex-wrap: wrap;
    gap: 30px 30px;
    width: 100%;
    
    .ck-product-item {
        @extend .ck-flex-y-start-center;
        position: relative;
        width: 48%;
        min-height: 100px;
        padding: 30px;
        border-radius: 2px;
        font-size: 18px;
        box-sizing: border-box;
        transition: background-color 0.25s 0s;
        background-color: $color-background;

        span { text-wrap: nowrap; }
        .ck-product-item-line,  .ck-product-item-line > div {
            position: absolute;
            width: 12px;
            height: 100%;
            top: 0;
            left: 0;

            :nth-child(1) {
                width: 4px;
                background-color: $color-main;
            }
            :nth-child(2) {
                width: 6px;
                left: 4px;
                background-color: rgb($color-grey, 6%);
            }
        }
        .ck-etc-list {
            margin: 8px 0 0 0;
        }
        .ck-clipper-gradual { 
            background: -moz-linear-gradient(270deg, $color-background 10%, rgb($color-background, 0) 100%);
            background: -webkit-linear-gradient(270deg, $color-background 10%, rgb($color-background, 0) 100%);
            background: linear-gradient(270deg, $color-background 10%, rgb($color-background, 0) 100%);
        }
    }
}

@media screen and (max-width: 940px) { 
    .ck-product-list {
        .ck-product-item {
            width: 46%;
        }
    }
}
@media screen and (max-width: 600px) { 
    .ck-product-list {
        .ck-product-item {
            width: 100%;
        }
    }
}
@media screen and (max-width: 540px) { 
    .ck-product-list {
        align-items: center;
        justify-content: center;
        gap: 30px 30px;

        .ck-product-item { 
            .ck-expander-info-container {
                overflow: hidden;
                .ck-clipper-gradual { top: 1px; }
            }
        } 
    }
 }