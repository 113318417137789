@import '../../../../sass/main.scss';

.ck-field-checkbox {
    @extend .ck-flex-x-start;
    position: relative;
    max-width: 340px;
    width: 100%;
    min-width: 240px;

    .ck-field-input-checkbox-simulator {
        display: inline-block;
        width: 20px;
        box-sizing: border-box;
        left: 0;
        cursor: pointer;
        
        .ck-field-checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            box-sizing: border-box;
            background-color: mix($color-white, desaturate($color-main, 38%), 90%);
            border: 1px solid lighten($color-grey, 38%);
            border-radius: 5px;
            
            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: $color-main;
                top: 5px;
                left: 5px;
                border-radius: 2px;
                display: none;
              }
        }
        .ck-field-input {
            width: 20px;
            height: 20px;
            display: none;
        }
        .ck-field-input:checked ~ .ck-field-checkmark::before {
            display: block;
        }
    } 
    .ck-field-label {
        font-size: 14px; 
        font-weight: 500;
        margin: 1px 0 0 0;
        padding: 0 0 0 12px;
        color: $color-grey;
    }

    &.ck-disabled {
        opacity: 0.5;
    }
}