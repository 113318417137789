@import '../../../sass';
.ck-greeting-section {
    height: 800px;
    padding: 0 0 0 0;
    box-sizing: border-box;
    .ck-section-content { max-width: 100%; }
}

.ck-greeting-slider {
  height: 100%; 

  .ck-slider-controls {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    max-width: $content-max-width;

    .ck-slider-controls-points { width: 100%; }
  }
}

.ck-greeting {
  @extend .ck-flex-x-center;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;

  .ck-greeting-background { 
    position:absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    min-height: 100%; 
    background: url('./images/background.jpg') no-repeat center top; 
    overflow: hidden;

    div {
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%;

      &:nth-child(1) {
        opacity: 0.5; 
        height: 100%; 
        transform: scale(1.02); 
        background: url('./images/background.jpg') no-repeat center top; 
        clip-path: url(#greetingBackgroundMask);
      }
      &:nth-child(2) {
        height: 120px; 
        opacity: 0; 
        background: url('./images/line.png') no-repeat center; 
        animation: greetingBackgroundAnimate1 6s 0s infinite;
      } 
      &:last-child {
        height: 200px; 
        opacity: 1; 
        top: auto;
        bottom: 0;
        background-color: $color-white;
        animation: greetingBackgroundAnimate3 2s 0s 1 forwards;
      }
    } 
    svg { 
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%;
      opacity: 0;
      rect { 
        animation: greetingBackgroundAnimate2 6s 0s infinite; 
      }
    }
  }
  @keyframes greetingBackgroundAnimate1 { 
    0% { top: 70%; opacity: 0; transform: translateY(0); }
    4.99% { top: 70%; opacity: 0; transform: translateY(0); }
    5% { opacity: 0.2; transform: translateY(0); }
    6% { opacity: 0.5; transform: translateY(40%); }
    7% { opacity: 0.2; transform: translateY(0); }
    10% { top: 80%; opacity: 0; } 
    10.01% { top: 50%; opacity: 0.5; } 
    10.05% { opacity: 0; } 
    11% { opacity: 0; } 
    12% { top: 20%; opacity: 0.1; } 
    13% { top: 22%; opacity: 0.1; } 
    13.05% { opacity: 0; }
    14% { top: 60%; opacity: 0.4; } 
    30% { transform: translateY(0); } 
    31% { transform: translateY(50%); } 
    32% { transform: translateY(0); } 
    33% { transform: translateY(50%); } 
    34% { transform: translateY(0); } 
    40% { top: 0; opacity: 0; }
    100% { top: 0; opacity: 0; }
  }
  @keyframes greetingBackgroundAnimate2 {
    0% { transform: translateY(-20%) scaleY(0.2); } 
    4% { transform: translateY(10%) scaleY(0.2); } 
    4.01% { transform: translateY(20%) scaleY(1); } 
    4.05% { transform: translateY(80%); }
    5% { transform: translateY(20%) scaleY(0.2); } 
    5.01% { transform: translateY(25%) scaleY(1); } 
    5.05% { transform: translateY(0); } 
    6% { transform: translateY(90%) scaleY(1); }
    10.01% { transform:translateY(40%) scaleY(1); } 
    10.5% { transform:translateY(45%); } 
    11.01% { transform:translateY(40%) scaleY(1); } 
    11.5% { transform:translateY(45%); }
    12.01% { transform:translateY(30%) scaleY(1); } 
    12.5% { transform:translateY(55%); } 
    13.01% { transform: translateY(40%) scaleY(1); } 
    13.5% { transform: translateY(55%); }
    14.01% { transform: translateY(60%) scaleY(0.2)} 
    14.5% { transform: translateY(65%); } 
    15.01% { transform: translateY(2%) scaleY(1); } 
    15.5% { transform: translateY(50%); }
    28% { transform: translateY(90%) scaleY(1); } 
    40% { transform: translateY(100%) scaleY(1); }  
    60% { transform: translateY(100%) scaleY(1); } 
    60.01% { transform: translateY(100%) scaleY(1); } 
    100% { transform: translateY(0) scaleY(0); }
  }
  @keyframes greetingBackgroundAnimate3 {
    0% { transform: translateY(0); opacity: 1; } 
    100% { transform: translateY(100%); opacity: 1; }
  } 
  .ck-greeting-info-container {
    @extend .ck-flex-x-start-center;
     position: relative;
     width: 100%;
     gap: 30px;
     max-width: $content-max-width;
     
     .ck-greeting-info {
      @extend .ck-flex-y-center;
      position: relative;
      width: 100%;
      height: 100%;

      h1 {
        font-size: 60px;
        font-weight: 500;
        text-transform: uppercase;
        color: $color-white;
        margin: 0;
        text-wrap: pretty;
      }
      p {
        font-size: 20px;
        color: $color-white;
        padding: 0 0 40px 0;
  
        span {
          text-wrap: nowrap;
        }
      }
    }
  
    .ck-greeting-info-image {
      width: 50%;

      img {
        float: right;
        max-width: 640px;
      }
    }
  }

}

@media screen and (max-width: 1390px) { 
  .ck-greeting {
    .ck-greeting-info { 
      width: 50%;
      h1 { font-size: 50px; }
    }
    .ck-greeting-image { width: 48%; }
  }
}

@media screen and (max-width: 1280px) { 
  .ck-greeting {
    .ck-greeting-info { width: 56%; }
    .ck-greeting-image { background-size: 90%; }
  }
  .ck-greeting-info-image {
    img { width: 100%; }
   }
}

@media screen and (max-width: 1230px) { 
  .ck-greeting {
    padding: 60px 30px 0 30px;
    .ck-greeting-info { width: 60%; }
    .ck-greeting-image { background-size: 100%; }
  }
  
}

@media screen and (max-width: 1020px) { 
  .ck-greeting-section { height: 700px; }
  
    .ck-greeting {
      align-items: center;
      justify-content: center;
      height: 700px;
      flex-direction: column;
      
      .ck-greeting-info-container {
        .ck-greeting-info { 
          width: 100%;
          max-width: 100%;
        }
        .ck-greeting-info-image  { display: none; }
      }
    }
}


@media screen and (max-width: 480px) { 
  .ck-greeting-section { height: 740px; }
  .ck-greeting { height: 740px; }
}

@media screen and (max-width: 440px) { 
  .ck-greeting { 
    .ck-greeting-info-container { 
      .ck-greeting-info { 
        width: 100%;
        h1 { font-size: 46px; }
      }
    }
  }
}