@import '../../../../sass/main.scss';

.ck-window-modal-wrapper {
  @extend .ck-flex-cover;
  @extend .ck-flex-x-center;
  visibility: hidden;
 
  .ck-window-modal-mask {
    visibility: hidden;
  }
  .ck-window-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 560px;
    min-height: 320px;
    max-height: 90%;
    border-radius: 1px;
    padding: 0 0 30px 0;
    box-sizing: border-box;
    box-shadow:  0px 8px 30px 0px rgb($color-black, 24%);
  
    .ck-window-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 1px;
        background-color: $color-white;
    }
    .ck-window-header {
      @extend .ck-flex-x-start-center;
      position: relative;
      width: 100%;
      min-height: 40px;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 30px 30px 0 0;
    }

    .ck-window-content {
      @extend .ck-flex-y-start-center;
      position: relative;
      max-height: 100%;
      padding: 0 20px 0 20px;
      margin: 10px;
      box-sizing: border-box;
      overflow: clip;
    }
    .ck-button-close {
      svg * { fill: $color-main; }
    }
    .ck-window-footer { 
      position: relative;
      display: inline; 
    }
    &.ck-window-modal-alert {
      min-height: 120px;
      min-width: 200px;
    }
    &.ck-window-rounded {
      border-radius: 30px;
      .ck-window-background {
        border-radius: 30px;
      }
    }
  }
  &.ck-show {
    @extend .ck-animate-visibility-on;

    .ck-window-modal-mask {
       @extend .ck-animate-visibility-on;
    }
    .ck-window-modal {
      .ck-window-background {
        @extend .ck-animate-scale-in;
      }
    }
  }
  &.ck-hide {
    @extend .ck-animate-visibility-off;

    .ck-window-modal-mask {
       @extend .ck-animate-visibility-off;
    }
  } 
  &.ck-scroll-content {
    .ck-window-content {
      overflow-y: auto;
    }
  } 
}

@media screen and (max-width: 680px) {
  .ck-window-modal-wrapper {
    .ck-window-modal {
      width: 100%;
      min-width: 300px;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      .ck-window-background { border-radius: 0; }
    }
    &.ck-show {
      .ck-window-modal {
        .ck-window-background {
          animation: none;
        }
      }
    }
  }
}