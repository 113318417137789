@import '../../sass';

.ck-footer {
   .ck-footer-button-scroll-up {
      top: -20px;
      width: 80px;
      height: 40px;
      border-radius: 2px;
   }
   
}



