@import '../../../../../sass/main.scss';

.ck-menu-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    @extend .ck-flex-cover;
    @extend .ck-flex-x-center;
    
    @extend .ck-animate-fade-pos-out-x;
    .ck-window-modal-mask { visibility: hidden; }
    &.ck-show {
        @extend .ck-animate-fade-pos-in-x;
        .ck-menu-modal-mask { @extend .ck-animate-visibility-on; }
        .ck-menu-modal {
          animation: mAnimatemenuModalShow 0.3s 0s 1 forwards;
          .ck-overlapper { animation: mAnimatemenuModalShowA 0.8s 0s 1 forwards linear; }
        }
    }
    &.ck-hide {
        @extend .ck-animate-fade-pos-out-x;
        .ck-menu-modal-mask { @extend .ck-animate-visibility-off }
        .ck-menu-modal {
          animation: mAnimatemenuModalHide 0.3s 0s 1 forwards;
          .ck-overlapper { animation: mAnimatemenuModalHideA 0.6s 0s 1 forwards linear; }
        }
    }
}

.ck-menu-modal {
    @extend .ck-flex-y-center-start;
    gap: 40px;
    position: absolute;
    width: 400px;
    height: 100%;
    right: 0;
    top: 0;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: $color-second;
    transform: translateX(100%);

    .ck-button-ico-close {
        svg * { fill: $color-white }
        width: 40px;
        height: 40px;
        svg * { transition: fill 0.2s 0s; }

        &.ck-enter {
          svg * { fill: $color-main; }
        }
    }
    .ck-menu-modal-items {
        @extend .ck-flex-y-center-start;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        li {
          gap: 15px;
          margin: 10px 0;
          width: 100%;
          text-align: center;
          list-style-type: none;
          color: $color-white; 
          font-size: 15px;
          font-weight: 500;
          text-transform: uppercase;
          transition: color 0.2s 0s;
          cursor: pointer;

          &.ck-press {
            @extend .ck-animate-press-button-default;
          }
          &.ck-enter {
            color: $color-main;
          }
        }

    }
    .ck-overlapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        opacity: 1;
        transform: translateX(100%);
        background-color: lighten($color-second, 5%);
    }
    @media screen and (max-width: 580px) { width: 100%; }
}
  
@keyframes mAnimatemenuModalShow {
  0% { opacity: 0.8;  transform: translateX(100%); }
  100% { opacity: 1; transform: translateX(0) }
}

@keyframes mAnimatemenuModalHide {
  0% { visibility: visible; opacity: 1; transform: translateX(0); }
  99.9% { visibility: visible; transform: translateX(100%); } 
  100% { visibility: hidden; opacity: 1; transform: translateX(100%); }
}

@keyframes mAnimatemenuModalShowA {
  0% { transform: translateX(100%); }
  50% { opacity: 1; transform: translateX(-92%); }
  100% { opacity: 0; transform: translateX(-100%) }
}

@keyframes mAnimatemenuModalHideA {
  0% { opacity: 1; transform: translateX(-100%); }
  100% { opacity: 1; transform: translateX(-50%) }
}