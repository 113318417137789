/* Flex - x / row */
.ck-flex-x {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start; 
}
.ck-flex-x-wrap {
    @extend .ck-flex-x;
    flex-wrap: wrap;
}
.ck-flex-x-start-center {
    @extend .ck-flex-x;
    align-items: center; 
}
.ck-flex-x-start-center-wrap {
    @extend .ck-flex-x-start-center;
    flex-wrap: wrap;
}
.ck-flex-x-start {
    @extend .ck-flex-x;
    align-items: start; 
}
.ck-flex-x-start-wrap {
    @extend .ck-flex-x-start;
    flex-wrap: wrap;
}

.ck-flex-x-end-center {
    @extend .ck-flex-x;
    justify-content: flex-end;
}
.ck-flex-x-end-center-wrap {
    @extend .ck-flex-x-end-center;
    flex-wrap: wrap;
}

.ck-flex-x-center {
    @extend .ck-flex-x;
    justify-content: center;
    align-items: center; 
}
.ck-flex-x-center-start {
    @extend .ck-flex-x;
    justify-content: center;
    align-items: start; 
}
.ck-flex-x-center-wrap {
    @extend .ck-flex-x-center;
    flex-wrap: wrap;
}

/* Flex - y / column */
.ck-flex-y {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}
.ck-flex-y-start-center {
    @extend .ck-flex-y;
    justify-content: center;
}
.ck-flex-y-start-center-wrap {
    @extend .ck-flex-y-start-center;
    flex-wrap: wrap;
}
.ck-flex-y-start {
    @extend .ck-flex-y;
    justify-content: start;
}
.ck-flex-y-start-wrap {
    @extend .ck-flex-y-start;
    flex-wrap: wrap;
}
.ck-flex-y-start-end {
    @extend .ck-flex-y;
    justify-content: start;
    align-items: end;
}

.ck-flex-y-center {
    @extend .ck-flex-y;
    justify-content: center;
    align-items: center; 
}
.ck-flex-y-center-wrap {
    @extend .ck-flex-y-center;
    justify-content: center;
    align-items: center; 
    flex-wrap: wrap;
}

.ck-flex-y-center-start {
    @extend .ck-flex-y;
    align-items: center;
}
.ck-flex-y-center-start-wrap {
    @extend .ck-flex-y-center-start;
    flex-wrap: wrap;
    
}
.ck-flex-y-end-start {
    @extend .ck-flex-y;
    align-items: end;
}
.ck-p-text-normal {
    max-width: 900px;
    font-size: 18px;
    margin: 0;
}
/* Lines */
.ck-line-x {
   width: 100%;
   height: 1px;
   background-color: $color-white;
}
.ck-line-y {
    width: 1px;
    height: 100%;
    background-color: $color-white;
 }

.ck-flex-cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
/** Elements */
.ck-disabler {
    @extend .ck-flex-x-center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb($color-white, 60%);
    display: none;
    
    svg.ck-ico-loader {
        width: 80px;
        height: 80px;;
    }
    * { fill: $color-main; }
}
/** */
.ck-clipper-gradual {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: -moz-linear-gradient(0deg, $color-white 0%, rgb($color-white, 0) 100%);
    background: -webkit-linear-gradient(0deg, $color-white 0%, rgb($color-white, 0) 100%);
    background: linear-gradient(0deg,$color-white 0%, rgb($color-white, 0) 100%);
}
/** */
.ck-text-size-normal {
    font-size: 18px;
}
.ck-background-color-gradient-main-lighten {
    background:  $color-main;
    background: -moz-linear-gradient(180deg, $color-main-lighten 0%, $color-main 100%);
    background: -webkit-linear-gradient(180deg, $color-main-lighten 0%, $color-main 100%);
    background: linear-gradient(180deg, $color-main-lighten 0%, $color-main 100%);
}
.ck-background-color-gradient-second-lighten {
    background:  $color-second;
    background: -moz-linear-gradient(180deg, $color-second 0%, $color-second-lighten 100%);
    background: -webkit-linear-gradient(180deg, $color-second 0%, $color-second-lighten 100%);
    background: linear-gradient(180deg, $color-second 0%, $color-second-lighten 100%);
}
/* Default animates */
.ck-animate-press-button-default {
    animation: ckAnimatePressButton 0.3s 0s 1 forwards;
}
.ck-animate-press-button-default-a {
    animation: ckAnimatePressButtonA 0.3s 0s 1 forwards;
}
.ck-animate-underline-default {
    animation: ckAnimateUnderlineDefault 0.4s 0s forwards;
}
.ck-animate-press-link-default {
    animation: ckAnimatePressButton 0.25s 0s 1 forwards;
}

.ck-animate-fade-in {
    animation: ckAnimateFadeIn 0.3s 0s 1 forwards;
}
.ck-animate-fade-out {
    animation: ckAnimateFadeOut 0.3s 0s 1 forwards;
}

.ck-animate-visibility-on {
    animation: ckAnimateVisibilityOn 0.4s 0s 1 forwards;
}
.ck-animate-visibility-off {
    animation: ckAnimateVisibilityOff 0.4s 0s 1 forwards;
}

.ck-animate-display-on {
    animation: ckAnimateDisplayOn 0.4s 0s 1 forwards;
}
.ck-animate-display-off {
    animation: ckAnimateDisplayOff 0.4s 0s 1 forwards;
}
.ck-animate-press-field-input {
    animation: ckAnimatePressFieldInput 0.6s 0s 1 forwards;
}
.ck-animate-scale-in {
    animation: ckAnimateScaleIn 0.5s 0s 1 forwards;
}
.ck-animate-scale-out{
    animation: ckAnimateScaleOut 0.5s 0s 1 forwards;
}
.ck-animate-fade-pos-in-x {
    animation: ckAnimateFadePosInX 0.4s 0s 1 forwards;
}
.ck-animate-fade-pos-out-x {
    animation: ckAnimateFadePosOutX 0.4s 0s 1 forwards;
}