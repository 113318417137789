@import '../../../sass';

.ck-advantages {
    opacity: 0; 
    &.ck-shown-scrolled {  animation: ckAnimateSectionFadeInY 0.6s 0.2s 1 forwards; }
}
.ck-advantages-info-container {
    @extend .ck-p-text-normal;
    @extend .ck-flex-x;
    align-items: stretch;
    gap: 30px;
    position: relative;
    min-width: 100%;

    .ck-advantages-image {
        position: relative;
        display: block;
        width: 50%;
        max-width: 680px;
        min-height: 540px;
        
        img { float: left; width: 100%; }
        div {
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0;
            overflow: hidden;

            > div {
              width: 200%;
              height: 100%;
              background-image: url(./images/image.jpg);
              background-position: center;
              background-repeat: no-repeat;
            }
            &:nth-child(1) {
              left: 0;
              > div { left: 0; }
            }
            &:nth-child(2) {
              right: 0;
              > div { left: -100%; }
            }
        }
        &.ck-scroll-in-y {
            > div:nth-child(1) { animation: advantagesImageInAnimateA 1.5s 0s 1 forwards; }
            > div:nth-child(2) { animation: advantagesImageInAnimateB 1.5s 0s 1 forwards; }
        }
        &.ck-scroll-out-y {
            > div:nth-child(1) { animation: advantagesImageOutAnimateA 0.5s 0.5s 1 forwards; }
            > div:nth-child(2) { animation: advantagesImageOutAnimateB 0.5s 0.5s 1 forwards;}
        }
    }
    @keyframes advantagesImageInAnimateA {
        0% { transform: translateY(-5%); opacity: 0; }
        100% { transform: translateY(0); opacity: 1;}
    }
    @keyframes advantagesImageInAnimateB {
        0% { transform: translateY(8%); opacity: 0; }
        10% { opacity: 0.4; } 11% { opacity: 0; } 12% { opacity: 0; } 12.5% { opacity: 0.3; }
        14% { opacity: 1; } 14.5% { opacity: 0.5; } 18% { opacity: 0.8; }
        20% { transform: translateY(0); }
        100% { transform: translateY(0); opacity: 1; }
    }
    @keyframes advantagesImageOutAnimateA { 100% { transform: translateY(-10); opacity: 0;} }
    @keyframes advantagesImageOutAnimateB { 100% { transform: translateY(10%); opacity: 0; } }
    .ck-advantages-info {
        position: relative;
        @extend .ck-flex-y;
        width: 50%;
        .ck-advantages-title-section {
            margin: 0;
            padding: 0 0 30px 0;
        }
        p { 
            flex: 1;
            padding: 0 0 20px 0;
            box-sizing: border-box;
        }
        span {
           display: inline-block;
           padding: 0 10px;
           margin: 0 0 8px 0;
           color: $color-main;
           background-color: rgb($color-main, 8%);
        }
    }
}

.ck-button-link-presentation { margin: 30px 0 0 0; }

@media screen and (max-width: 1280px) { 
    .ck-advantages-info { 
        max-width: 100%; 
        * { font-size: 20px; }
    }
}
@media screen and (max-width: 1000px) { 
    .ck-advantages {
        .ck-section-background { background-color: $color-black; }
        .ck-advantages-info-container {
            .ck-advantages-info { 
                p {color: $color-white; }
                flex-wrap: wrap;
                max-width: 100%; 
                width: 100%;
                .ck-advantages-info-text { width: 100%; font-size: 20px; }
                .ck-advantages-info-image { width: 100%; }
                .ck-title-section { * { color: $color-white; } }
            }
            .ck-advantages-image { display: none; }
        }
    }
}
@media screen and (max-width: 800px) { 
    .ck-advantages-info { 
        max-width: 100%; 
        p { font-size: 18px; margin: 10px 0; }
        span {  font-size: 18px; margin: 0 0 10px 0;  }
    }
}
