@import '../../../../sass/main.scss';

.ck-label {
    &.ck-label-alert {
        min-height: 24px;
        box-sizing: border-box;
        background-color: rgb($color-black, 10%);
        .ck-label-ico {
            div:nth-child(1) { background-color: rgb($color-black, 25%); } 
        }

        &.ck-negative {
            background-color: rgb($color-red, 10%);
            svg * { fill: $color-red; }
            .ck-label-ico {
                div:nth-child(1) { background-color: rgb($color-red, 35%); } 
            }
        }
        &.ck-positive {
            background-color: rgb($color-green, 10%);
            svg * { fill: $color-green; }
            .ck-label-ico {
                div:nth-child(1) { background-color: rgb($color-green, 35%); } 
            }
        }
        &.ck-main {
            background-color: rgb($color-main, 32%);
            svg * { fill: $color-main; }
            .ck-label-ico {
                div:nth-child(1) { background-color: rgb($color-main, 35%); } 
            }
        }
    }
}
