@import '../../../../sass/main.scss';

.ck-form {
  @extend .ck-flex-y-center-start;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: clip;

  .ck-form-fields {
     @extend .ck-flex-y-center-start;
     flex-wrap: nowrap;
     gap: 20px;
     width: 100%;
     padding: 30px 10px;
     max-height: 100%;
     box-sizing: border-box;
     overflow-y: auto;
  }
  .ck-button-lined-soft {
    .ck-button-text {
      color: $color-main;
    }
  }
  .ck-form-buttons{
      margin: 20px  0 0 0;
  }
  .ck-form-info { display: block; }
  .ck-form-disabler { 
    @extend .ck-disabler; 
  }

  &.ck-disabled {
    .ck-form-disabler {
      display: flex;
    }
  }
}
