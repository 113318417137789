@import '../../sass';

.ck-header {
     position: absolute;
     display: block;
     width: 100%;
     height: 100px;
     padding: 0 30px;
     top: 0;
     left: 0;
     box-sizing: border-box;
     background: linear-gradient(180deg, black, transparent);

     .ck-header-content {
       @extend .ck-flex-x-start-center;
       max-width: $content-max-width;
       height: 100%;
       margin: 0 auto;
       gap: 20px;
       
     }
     .ck-button-ico-burger { display: none; }
     .ck-button-feedback { 
        display: block; 
     }

     &.minimized {
       .ck-hedaer-menu-links {
         padding: 0; 
         a {  visibility: hidden; }
       }
       .ck-button-ico-burger { display: block; min-width: 24px; min-height: 24px; }
       .ck-button-feedback { display: none; }
     } 
}

.ck-hedaer-menu-links {
     @extend .ck-flex-x-center;
     position: relative;
     flex: 1;
     flex-shrink: 0;
     padding: 4px 110px 4px 0;
     overflow: hidden;
   
     a {
      @extend .ck-flex-x-center;
       position: relative;
       font-weight: 500;
       font-size: 15px;
       text-transform: uppercase;
       text-decoration: none;
       white-space: nowrap;
       color: $color-white;
       padding: 0 15px;
       cursor: pointer;
   
       &.ck-press { @extend .ck-animate-press-link-default; }
       &.active, &:hover {
         color: $color-main;
         &::after {
           content: '';
           position: absolute;
           bottom: -4px;
           width: calc(100% - 30px);
           height: 3px;
           background-color: $color-main;
           @extend .ck-animate-underline-default;
         }
       }
     }
}

@media screen and (max-width: 1080px) { 
  .ck-header {
    .ck-button-feedback { display: none; }
  }
}

@media screen and (max-width: 800px) { 
  .ck-header {
    .ck-header-logo { 
      height: 100%;
      width: 260px;

      svg { cursor: pointer; }
      .ck-line-y { height: 60%; }
      p { 
        color: $color-white; 
        font-size: 14px;
        margin: 0 0 0 5px;
      }
    }
  }
}