@import '../../../../../sass/main.scss';

.ck-section { 
    position: relative;
    display: block;
    width: 100%;
    padding: $section-padding;
    box-sizing: border-box;
    overflow: hidden;

    .ck-section-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center right;
    }

    .ck-section-content {
        @extend .ck-flex-y-start;
        position: relative;
        max-width: $content-max-width;
        height: 100%;
        margin: 0 auto;
    }

    &:last-of-type {
        padding: 70px 20px 200px 20px;
    } 
}

@media screen and (max-width: 780px) { 
    .ck-section { 
      padding: 70px 30px;
    }
}