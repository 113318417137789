@import '../../../sass/main.scss';

.ck-slider {
    @extend .ck-flex-x-center;
    position: relative;
    width: 100%;
    min-width: 300px;
    height: 600px;
    box-sizing: border-box;
    background-color: rgb($color-main, 2%);

    /* Slide contents */
    .ck-slides {
      @extend .ck-flex-x-start-center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;

      .ck-slides-wrapper {
         @extend .ck-flex-x-start-center;
         display: block;
         min-width: 100%;
         height: 100%;
         top: 0;
         left: 0;
      }
      .ck-slides-mover {
        height: 100%;
        width: 0;
        transition: margin-left 0.45s 0s;
      }

      .ck-slide {
        @extend .ck-flex-x-center;
        position: relative;
        flex-shrink: 0;
        float: left;
        width: 100%;
        height: 100%;
 
        &.ck-prev { transform: translateX(0); }
        &.ck-curr { transform: translateX(0); }
        &.ck-next { transform: translateX(0); }
      }

      &.ck-animate-prev {
        .ck-curr {  transform: translateX(100%); }
        .ck-prev {  transform: translateX(100%); }
        .ck-next {  transform: translateX(0); }
      }
      &.ck-animate-next {
        .ck-curr {  transform: translateX(-100%); }
        .ck-prev {  transform: translateX(0); }
        .ck-next {  transform: translateX(-100%); }
      }
    }
    /* Point stepl buttons */
    .ck-slider-controls-points  { 
        @extend .ck-flex-x-center;
        position: absolute;
        bottom: 10px;
        gap: 20px; 

        .ck-point {
          @extend .ck-flex-x-center;
          position: relative;
          width: 40px;
          height: 40px;
          cursor: pointer;
    
          div {
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: rgb($color-main, 30%);
          }
          &.ck-active {
            div { 
                @extend .ck-animate-press-button-default-a;
                background-color: $color-main; 
            }
        }
      }
    }

   /* Prev/next step buttons */
   .ck-prev {
      @extend .ck-flex-x-center;
      position: absolute;
      width: 80px;
      height: 80px;
      left: 0;
      bottom: 0;
      cursor: pointer;

      * { fill: $color-main; }

      &.ck-press {
        @extend .ck-animate-press-button-default;
      }
   }
   .ck-next {
      @extend .ck-prev;
      left: auto;
      right: 0;
      bottom: 0;
   } 

   &.ck-single-slide {
     .ck-slides { @extend .ck-flex-x-center; }
   }
   /* Media query */
   @media screen and (max-width: 900px) { .ck-prev, .ck-next { visibility: hidden; } }
   @media screen and (max-width: 540px) { .ck-points { gap: 0; } }
}

/* Overlapps */
.ck-slider-overlapps {
   visibility: hidden;
  .ck-overlapp { 
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; 
  }
   
  &.ck-slide-frock-right { 
    .ck-overlapp-curr {  transform: translateX(-100%); }
    .ck-overlapp-prev {  transform: translateX(0); }
    .ck-overlapp-next {  transform: translateX(-100%); }
 }

  &.ck-slide-frock-left { 
    .ck-overlapp-curr {  transform: translateX(100%); }
    .ck-overlapp-prev {  transform: translateX(100%); }
    .ck-overlapp-next {  transform: translateX(0); }
  }

  @keyframes mAnimateSlideFromLeft {
    0% { transform: translateX(0); }
    99.9% { transform: translateX(100%); }
    100% { transform: translateX(100%); }
  }
  @keyframes mAnimateSlideFromRight {
    0% { transform: translateX(0); }
    99.9% { transform: translateX(-100%); }
    100% { transform: translateX(-100%); }
 }
}



