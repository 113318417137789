@import '../../../sass';

.ck-window-modal-wrapper {
    .ck-feedback-win {

        .ck-window-background {
            background: linear-gradient(180deg, white, #d3dddf);
        }
        .ck-window-header  {
            .ck-title-window {
                .ck-title-text {
                    color: $color-second;
                }
            }
        }
        .ck-title-tools {
          .ck-button-ico { svg * { fill: $color-second; }}
        }
        .ck-field {
            .ck-field-label {
                color: $color-second;
            }
            .ck-input-container {
                .ck-under-input-bground {
                    background-color: mix($color-white, $color-grey, 90%);
                }
                input {
                    background-color: mix($color-white, $color-grey, 90%);
                    border: 1px solid desaturate(mix($color-white, $color-main, 70%), 50%);
                }
            }
        }
        .ck-field-checkbox {
            .ck-field-label { 
                color: $color-second; 
                a { 
                    color: $color-second; 
                    &:hover { text-decoration: underline; }
                    
                }
            }
        }
        .ck-form {
            .ck-form-disabler { 
                background-color: rgb($color-white, 50%); 
                svg * { fill: $color-white; }
            }
            .ck-button-disabler { background-color: rgb($color-white, 30%); }
        }
    }
}

