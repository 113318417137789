@import '../../../../sass/main.scss';

@mixin ck-title-section {
    position: relative;
    @extend .ck-flex-x-start-center;
    max-width: $content-max-width;
    margin: $title-section-margin;

    .ck-title-text {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 500; 
        padding: 0 0 20px 0;
        margin: 0;
        box-sizing: border-box;
        color: $color-main;

        .ck-title-lines {
            position: absolute;
            height: 10px;
            width: 100%;
            bottom: 0;
            background-color: desaturate(rgb($color-main, 30%), 16%);

            div {
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $color-main;

                &:nth-child(1) {
                    top: 0;
                    left: -10px;
                    padding: 0 10px;
                }
                &:nth-child(2) {
                    bottom: 0;
                    left: -10px;
                    padding: 0 10px;
                }
            }
        }
    }
    &.ck-align-left {
        @extend .ck-flex-x-start-center;
    }
    &.ck-align-right {
        @extend .ck-flex-x-end-center;
        .ck-title-text { padding: 0 0 10px 0; }
        .ck-title-lines {
            flex-direction: row-reverse;
            left: auto;
            right: 0;
        }
    }
    &.ck-align-center { @extend .ck-flex-x-center; }
    &.ck-color-white {
        .ck-title-text {
            color: $color-white;
            .ck-title-decoration {
                div:nth-child(1) { background-color: rgb($color-white, 18%); }
                div:nth-child(2) { background-color: $color-white; }
                div:nth-child(3) { color: $color-white; }
            }
        }
    }
    &.ck-color-black {
        .ck-title-text {
            color: $color-black;
            .ck-title-decoration {
                div:nth-child(1) { background-color: rgb($color-black, 18%); }
                div:nth-child(2) { background-color: $color-black; }
                div:nth-child(3) { color: $color-black; }
            }
        }
    }
    &.ck-color-main {
        .ck-title-text {
            color: $color-main;
            .ck-title-decoration {
                div:nth-child(1) { background-color: rgb($color-main, 18%); }
                div:nth-child(2) { background-color: $color-main; }
                div:nth-child(3) { color: $color-main; }
            }
        }
    }
    &.ck-color-second {
        .ck-title-text {
            color: $color-second;
            .ck-title-decoration {
                div:nth-child(1) { background-color: rgb($color-second, 18%); }
                div:nth-child(2) { background-color: $color-second; }
                div:nth-child(3) { color: $color-second; }
            }
        }
    }
    &.ck-size-s {
        .ck-title-text {
            font-size: 22px;
            font-weight: 500; 
        }
    }
    &.ck-size-l {
        .ck-title-text {
            font-size: 34px;
            font-weight: 500; 
        }
    }
    /** Global modification */
    &.ck-mod-a {
        .ck-title-text {
            padding: 0 0 10px 40px;
            .ck-title-decoration { display: block; }
        }
        &.ck-align-right {
            .ck-title-text {
                padding: 0 35px 10px 0;
            }
        }
    }
}

.ck-title-section {
    @include ck-title-section;
}

@media screen and (max-width: 800px) { 
    .ck-title-section {
        .ck-title-text {
            padding: 0 0 20px 0px;
            .ck-title-decoration { display: none; }
        }
    }
}