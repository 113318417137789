@import '../../../../../../sass/main.scss';

.ck-button-special-animated-1 {
  @extend .ck-flex-x-center;
  position: relative;
  min-width: 190px;
  height: 46px;
  border-radius: 1px;
  cursor: pointer;
  
  .ck-button-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-main;
        transition: background-color 0.25s 0s;
        animation: ckButtonNextDownAnimateA 8s 0s infinite forwards;
    }
  }
  @keyframes ckButtonNextDownAnimateA {
    0% { transform: translateY(0); opacity: 1;}
    5% { transform: translateY(0); opacity: 1;}
    5.1% { transform: translateY(-100%); opacity: 0;}
    10% { transform: translateY(100%); opacity: 1; }
    10.1% { transform: translateY(-100%); opacity: 0; }
    15% { transform: translateY(0); opacity: 1; }
  }
  .ck-button-text { 
    @extend .ck-flex-x-center;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1px 0 0 0;
    box-sizing: border-box;
    text-align: center;
    color: $color-black;
    font-size: 14px;
    font-weight: 600; 
    overflow: hidden; 
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .ck-ico-down {
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 0;
    * { fill: $color-main; }
    animation: ckButtonNextDownAnimateB 8s 0s infinite forwards;
    @keyframes ckButtonNextDownAnimateB {
      0% { transform: translateY(-50px); opacity: 0;}
      5% { transform: translateY(-50px); opacity: 0;}
      8% { transform: translateY(-30px); opacity: 1;}
      12% { transform: translateY(35px); opacity: 1;}
      15% { transform: translateY(50px); opacity: 0;}
      100% { transform: translateY(50px); opacity: 0;}
    }
  }
  .ck-ico-line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    background-color: $color-main;
    animation: ckButtonNextDownAnimateC 8s 0s infinite forwards;
  }  
  @keyframes ckButtonNextDownAnimateC {
    0% { transform: translateY(-200%) scaleY(0.5); opacity: 0;}
    8% { transform: translateY(-100%) scaleY(1); opacity: 0;}
    18% { transform: translateY(150%) scaleY(0); opacity: 1;}
    100% { transform: translateY(150%) scaleY(0); opacity: 0;}
  }
  .ck-ico-triangle {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 7px;
    top: 50%;
    margin: -12px 0 0 0;
    overflow: hidden;

    * { fill: $color-main; }
  }
  .ck-button-disabler {
    @extend .ck-disabler;
    border-radius: 1px;
  }

  &.ck-enter:not(.ck-disabled) {
    .ck-button-background {
        div { background-color: lighten($color-main, 8%); }
    }
     
  }
  &.ck-press:not(.ck-disabled) {
    @extend .ck-animate-press-button-default;
  }
  &.ck-disabled {
    cursor: default;
    opacity: 0.5;
    .ck-button-disabler { display: block; }
  }
  &.ck-button-text-color-white {
    .ck-button-text { color: $color-white; }
  }
}

.ck-button-special-animated-2 {
  @extend .ck-flex-x-center;
  position: relative;
  min-width: 190px;
  height: 46px;
  border-radius: 1px;
  cursor: pointer;
  
  .ck-button-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-main;
        transition: background-color 0.25s 0s;
        animation: ckButtonFeedbackAnimateA 8s 0s infinite forwards;
    }
  }
  @keyframes ckButtonFeedbackAnimateA {
    0% { transform: translateX(0); opacity: 1;}
    5% { transform: translateX(0); opacity: 1;}
    5.1% { transform: translateX(-100%); opacity: 0;}
    10% { transform: translateX(100%); opacity: 1; }
    10.1% { transform: translateX(-100%); opacity: 0; }
    15% { transform: translateX(0); opacity: 1; }
  }
  .ck-button-text { 
    @extend .ck-flex-x-center;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1px 0 0 0;
    box-sizing: border-box;
    text-align: center;
    color: $color-black;
    font-size: 14px;
    font-weight: 600; 
    overflow: hidden; 
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .ck-button-disabler {
    @extend .ck-disabler;
    border-radius: 1px;
  }
  &.ck-enter:not(.ck-disabled) {
    .ck-button-background { div { background-color: lighten($color-main, 8%); } } 
  }
  &.ck-press:not(.ck-disabled) { @extend .ck-animate-press-button-default; }
  &.ck-disabled {
    cursor: default;
    opacity: 0.5;
    .ck-button-disabler { display: block; }
  }
}


.ck-animated-ellipses-a, .ck-animated-ellipses-b {
  @extend .ck-flex-x-center;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;

  div {
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid $color-main;
    transform-origin: center;
    opacity: 0;
    
    &:nth-child(1) {
        width: 28%;
        border: none;
        background-color: $color-main;
    }
    &:nth-child(2) { width: 54%; }
    &:nth-child(3) { width: 100%; }
  }
}

.ck-animated-ellipses-a {
  :nth-child(1) { animation: animateButtonPanelEllipseA_1 5s 0s forwards infinite; }
  :nth-child(2) { animation: animateButtonPanelEllipseA_2 5s 0s forwards infinite; }
  :nth-child(3) { animation: animateButtonPanelEllipseA_3 5s 0s forwards infinite; }
  @keyframes animateButtonPanelEllipseA_1 {
    0% { opacity: 0; transform: scale(0.5); } 4% { opacity: 1; transform: scale(1); } 
    4.1% { opacity: 0; } 5% { opacity: 0.2; } 6%{ opacity: 1; transform: scale(1); }
    12% { opacity: 0; transform: scale(0); } 100% { opacity: 0; }
  }
  @keyframes animateButtonPanelEllipseA_2 {
    4% { opacity: 0; } 5% { opacity: 1; } 
    6% { opacity: 0; } 7% { opacity: 1; } 8% { opacity: 1; } 
    18% { opacity: 0;} 100% { opacity: 0; }
  }
  @keyframes animateButtonPanelEllipseA_3 {
    0% { opacity: 0; transform: scale(0.5); } 5% { opacity: 0; } 
    15% { opacity: 1; transform: scale(1); } 35% { opacity: 0; } 
  }
}
.ck-animated-ellipses-b {
 left: auto;
 right: 0;
 :nth-child(1) { animation: animateButtonPanelEllipseB_1 5s 1s forwards infinite; }
 :nth-child(2) { animation: animateButtonPanelEllipseB_2 5s 1s forwards infinite; }
 :nth-child(3) { animation: animateButtonPanelEllipseB_3 5s 1s forwards infinite; }
 @keyframes animateButtonPanelEllipseB_1 {
   0% { opacity: 0; transform: scale(0.5); } 4% { opacity: 1; transform: scale(1); } 
   4.1% { opacity: 0; } 5% { opacity: 0.2; } 6%{ opacity: 1; transform: scale(1); }
   12% { opacity: 0; transform: scale(0); } 100% { opacity: 0; }
 }
 @keyframes animateButtonPanelEllipseB_2 {
   4% { opacity: 0; } 5% { opacity: 1; } 
   6% { opacity: 0; } 7% { opacity: 1; } 8% { opacity: 1; } 
   18% { opacity: 0;} 100% { opacity: 0; }
 }
 @keyframes animateButtonPanelEllipseB_3 {
   0% { opacity: 0; transform: scale(0.5); } 5% { opacity: 0; } 
   15% { opacity: 1; transform: scale(1); } 35% { opacity: 0; } 
 }
}


