@import '../../sass';

.ck-feedback {
   @extend .ck-flex-y-center-start;
   width: 100%;
   p { color: $color-black; }
}



