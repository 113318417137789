@import '../../../../sass/main.scss';
@import '../../../../sass/main.scss';

/** Main */
.ck-panel {
    @extend .ck-flex-y-start;
    width: 100%;
    max-width: $content-max-width;
    min-height: 24px;
    padding: 4px;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: mix($color-white, mix($color-grey, $color-main, 40%), 95%);   
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.54); 
    box-shadow: 0px 8px 15px -10px rgba(0,0,0,0.2);
    
    .ck-panel-border {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        box-sizing: border-box;
        border: solid 1px rgb(mix($color-grey, $color-main, 50%), 8%);
    }
    .ck-panel-header {
        @extend .ck-flex-x-start;
        position: relative;
        align-items: stretch;
        width: 100%;
        min-height: 24px;
        padding: 5px 10px 5px 15px;
        box-sizing: border-box;
        border-radius: 50px;
        background: linear-gradient(360deg, mix($color-white, mix($color-grey, $color-main, 10%), 80%), mix($color-white, mix($color-grey, $color-main, 30%), 86%));

        .ck-panel-headert-title-ico {
            position: relative;
            width: 24px;
            margin: 0 5px 0 0;
            border-radius: 12px;
            svg { * { fill: $color-main; } }
         }
         .ck-panel-header-title {
            flex: 1;
            padding: 0 10px 0 0;
            font-size: 18px;
            box-sizing: border-box;
            color: $color-main-darken;
            user-select: none;
            span { display: inline-block; }
         }
         .ck-panel-header-tools {
            .ck-button-ico {
                &.ck-panel-expand-ico {
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                    background-color: rgb($color-main, 5%);
                    svg { * { fill: $color-main; } }
                }
             }
         }
    }
    .ck-panel-body {
        @extend .ck-flex-y-start;
        position: relative;
        height: 0;
        min-height: unset;
        max-height: 320px;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: auto;
        
        .ck-panel-body-inner {
            position: relative;
            font-size: 17px;
            color: $color-black;
        }
        .ck-clipper-gradual { 
            @extend .ck-animate-display-on; 
            background: -moz-linear-gradient(0deg, $color-white 0%, rgb($color-white, 0) 100%);
            background: -webkit-linear-gradient(0deg, $color-white 0%, rgb($color-white, 0) 100%);
            background: linear-gradient(0deg,$color-white 0%, rgb($color-white, 0) 100%);
        }
        &::-webkit-scrollbar { width: 4px; }
    }
    &.ck-expanded { 
        .ck-panel-body { 
            height: auto; 
            padding: 5px 15px;
            min-height: 50px;
        } 
    } 
    &.ck-disabled { 
        .ck-disabler { display: block; } 
    }  
}

