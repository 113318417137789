@import '../../sass';

.ck-logo-header, .ck-logo-footer { 
  @extend .ck-flex-x-start-center;
  height: 100%;
  color: $color-white; 
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: left center;
  cursor: pointer;
  
  .ck-logo-ico {
    width: 60px;
    height: 60px;
    background-image: url('./images/logo-ico.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
  .ck-line-y { 
    height: 60px; 
    margin: 0 10px;
    opacity: 0.5;
  }
}

.ck-logo-footer {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  height: 60px;

  .ck-logo-ico {  background-size: 40px 40px; }
  .ck-line-x {
    position: absolute;
    width: 100%;
    height: 1px;
    right: 0;
    background-color: $color-white;

    &:nth-child(1) { top: 10px; } 
    &:nth-child(2) { bottom: 11px; }
  }
}