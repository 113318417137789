/* $color-main: #BB4430; */
$color-main: #00b9e7;
$color-main-lighten: lighten($color-main, 7%);
$color-main-lighten-a: lighten($color-main, 11%);
$color-main-darken: darken($color-main, 5%);
$color-second: #181818;
$color-second-lighten: lighten($color-second, 7%);
$color-second-lighten-a: lighten($color-second, 11%);
$color-black: #1d1d1d;
$color-black-a: #181717;
$color-white: #fff;
$color-white-a: #fff;
$color-grey: #808080;
$color-gray: #808080;
$color-green: #08924c;
$color-red: #f22000;

$color-title-window: $color-second;
$color-title-section: $color-second;


