@import '../../../../sass/main.scss';

.ck-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  
  .ck-mask-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: mix(black, $color-main, 90%);
    opacity: 0.5
  }
  
  &.ck-show {
    @extend .ck-animate-visibility-on;
  }
  &.ck-hide{
    @extend .ck-animate-visibility-off;
  }
}
