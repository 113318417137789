@import '../../../../../sass/main.scss';

.ck-button-link {
  @extend .ck-flex-x-start-center;
  display: inline-flex;
  position: relative;
  min-width: 140px;
  max-width: 360px;
  height: 34px;
  padding: 0 25px;
  box-sizing: border-box;
  border-radius: 17px;
  cursor: pointer;
  
  .ck-text { 
    @extend .ck-flex-x-center;
    width: 100%;
    height: 100%;
    text-align: center;
    color: $color-main;
    font-size: 14px;
    font-weight: 500; 
    overflow: hidden; 
  }

  .ck-ico {
    min-width: 24px;
    height: 24px;
    margin: 0 -10px 0 10px;
    overflow: hidden;

    * { fill: $color-main; }
  }
  &.ck-enter {
    .ck-text {
      text-decoration: underline;
    }
  }
  &.ck-press {
    @extend .ck-animate-press-button-default;
  }

  &.ck-icon-right {
    visibility: dvisible;
    right: 6px;
  }
}

.ck-button-link-soft {
  @extend .ck-button-link;
  background-color: mix($color-main, $color-white, 12%);
  transition: background-color 0.2s 0s;

  .ck-text { 
    color: $color-main;
    font-size: 14px;
  }
  &.ck-enter {
    background-color: lighten(mix($color-main, $color-white, 12%), 3%);
    .ck-text { text-decoration: none; }
  }
}