html { 
    height: 100%; 
  
    * { font-family: Roboto, "Open Sans", Helvetica, Arial, sans-serif; }
    a { 
      cursor: pointer; 
      color: #ecab04;
    }
    body {
      margin: 0;
      height: 100%;
    }
  }
  
  div {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: mix(#ffffff, desaturate(#1b1b1b, 60%), 85%);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 40px mix(#ffffff, desaturate(#F6C90E, 40%), 60%);
      border-radius: 5px;
    }
  }

  .grecaptcha-badge {
    display:none !important;
}