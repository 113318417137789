@import '../../../../sass/main.scss';

.ck-etc-list {
   position: relative;
   max-width: 100%;
   padding: 5px 0;
   white-space: nowrap;
   overflow: hidden;
   
   .ck-etc-list-item {
      display: inline-block;
      position: relative;
      padding: 3px 7px;
      margin: 0 8px 0 0;
      color: $color-main;
      font-size: 16px;
      font-weight: 500; 
      background-color: mix($color-white, mix($color-gray, $color-main, 50%), 90%);
   }
   .ck-clipper-gradual { 
      position: absolute;
      width: 20%;
      min-width: 30px;
      height: 100%;
      top: 0;
      left: auto;
      right: 0;
      background: -moz-linear-gradient(270deg, $color-white 0%, rgb($color-white, 0) 100%);
      background: -webkit-linear-gradient(270deg, $color-white 0%, rgb($color-white, 0) 100%);
      background: linear-gradient(270deg, $color-white 0%, rgb($color-white, 0) 100%);
  }
   &.ck-size-s {
      .ck-etc-list-item { 
         padding: 4px 6px;
         margin: 0 8px 0 0;
         font-size: 12px; 
      }
   }
   &.ck-size-m {
      .ck-etc-list-item { font-size: 14px; }
   }
   &.ck-size-l {
      .ck-etc-list-item { font-size: 20px; }
   }
}
