@import '../../../../sass/main.scss';

.ck-title-window {
    @extend .ck-flex-x-start-center;
    position: relative;
    width: 100%;
    min-height: 24px;
    padding: 0 30px 0 0;

    .ck-title-text {
        max-width: 380px;
        flex: 1;
        position: relative;
        font-size: 18px;
        font-weight: 500; 
        text-transform: uppercase;
        margin: 0;
        box-sizing: border-box;
        color: $color-main;
    }
    .ck-title-tools {
      @extend .ck-flex-y-center;
      position: absolute;
      top: 0;
      right: 0

    }

    &.ck-align-right {
      @extend .ck-flex-x-end-center;
      .ck-title-text { padding: 0 0 0 0; }
    }
    &.ck-align-center { 
      @extend .ck-flex-x-center; 
      padding: 0 25px;
      
      p { text-align: center; }
    }
    &.ck-color-white {
      .ck-title-text { color: $color-white; }
    }
}

@media screen and (max-width: 480px) { 
    .ck-title-window {
        .ck-title-text { 
          width: 100%; 
          padding: 0 0 0 0;
          text-align: center;}
    }
}
