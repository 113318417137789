@import '../../../../sass/main.scss';

.ck-label {
    @extend .ck-flex-x-start-center;
    min-height: 24px;
    padding: 0 8px 0 8px;

    .ck-label-ico {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 24px;
        min-height: 24px;
        height: 100%;
        left: -6px;

        div:nth-child(1) {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 9px;
        }
        div:nth-child(2) {
            position: absolute;
            width: 24px;
            height: 24px;
            background-position: center;

        }
    }

    .ck-label-text {
        font-size: 16px;
        color: $color-white;
        margin: -1px 0 0 0;
    }
}