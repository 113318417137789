@import '../../../sass';

.ck-about {
    padding: 120px 20px 100px 20px;
    opacity: 0; 
    &.ck-shown-scrolled { animation: ckAnimateSectionFadeInY 0.6s 0.2s 1 forwards; }
}
.ck-about-info-container {
    @extend .ck-p-text-normal;
    @extend .ck-flex-x;
    align-items: stretch;
    gap: 30px;
    position: relative;
    min-width: 100%;

    .ck-about-image {
        position: relative;
        display: block;
        width: 50%;
        max-width: 680px;
        min-height: 540px;
        
        img { float: left; width: 100%; }
        div {
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0;
            overflow: hidden;

            > div {
              width: 200%;
              height: 100%;
              background-image: url(./images/image.jpg);
              background-repeat: no-repeat;
              background-position: center;
            }
            &:nth-child(1) {
              left: 0;
              > div { left: 0; }
            }
            &:nth-child(2) {
              right: 0;
              > div { left: -100%; }
            }
            &:nth-child(3) {
                width: 100%;
                background-image: url(./images/image-2.jpg);
                clip-path: url(#aboutImageMask);
            }
        }
        svg { 
            position: absolute; 
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%;
            opacity: 0;
            rect { opacity: 0; }
        }
        &.ck-scroll-in-y {
            > div:nth-child(1) { animation: aboutImageInAnimate1 1.5s 0s 1 forwards; }
            > div:nth-child(2) { animation: aboutImageInAnimate2 1.5s 0s 1 forwards; }
            svg rect { animation: aboutImageInAnimate3 1.5s 0s 1 forwards linear; }
        }
        &.ck-scroll-out-y {
            > div:nth-child(1) { animation: aboutImageOutAnimate1 0.5s 0.5s 1 forwards; }
            > div:nth-child(2) { animation: aboutImageOutAnimate2 0.5s 0.5s 1 forwards;}
            svg rect { transform: scaleY(0); animation: aboutImageOutAnimate3 1.5s 0s 1 forwards; }
        }
    }
    @keyframes aboutImageInAnimate1 {
        0% { transform: translateY(-5%); opacity: 0; }
        100% { transform: translateY(0); opacity: 1;}
    }
    @keyframes aboutImageInAnimate2 {
        0% { transform: translateY(8%); opacity: 0; }
        10% { opacity: 0.4; } 11% { opacity: 0; } 12% { opacity: 0; } 12.5% { opacity: 0.3; }
        14% { opacity: 1; } 14.5% { opacity: 0.5; } 18% { opacity: 0.8; }
        20% { transform: translateY(0); }
        52.9% { transform: translateY(0); }
        53% { transform: translateY(2%); }
        55% { transform: translateY(0); }
        100% { transform: translateY(0); opacity: 1; }
    }
    @keyframes aboutImageInAnimate3 {
        0% { transform: translateY(20%) scaleY(0); }
        5% { transform: translateY(20%) scaleY(0.3); }
        30% { transform: translateY(34%) scaleY(0.2); }
        33% { transform: translateY(40%) scaleY(0.5); }
        34% { transform: translateY(38%) scaleY(0.4); }
        50% { transform: translateY(50%) scaleY(1); }
        51% { transform: translateY(52%) scaleY(0.5); }
        52% { transform: translateY(48%) scaleY(0.8); }
        53% { transform: translateY(62%) scaleY(0.7); }
        100% { transform: translateY(100%) scaleY(0); }
    }
    @keyframes aboutImageOutAnimate1 {
        100% { transform: translateY(-10); opacity: 0;}
    }
    @keyframes aboutImageOutAnimate2 {
        100% { transform: translateY(10%); opacity: 0; }
    }
    .ck-about-info {
        width: 50%;
        .ck-about-title-section {
            padding: 0 0 50px 0;
        }
        p { 
            margin: 0 0 20px 0; 
            margin: 0 0 10px 0;
        }
        p:first-child {
            margin: 0 0 20px 0;
        }
        span {
           display: inline-block;
           padding: 0 10px;
           margin: 0 0 8px 0;
           color: $color-main;
           background-color: rgb($color-main, 8%);
        }
    }
}

.ck-button-link-presentation {
    margin: 30px 0 0 0;
}

@media screen and (max-width: 1280px) { 
    .ck-about-info { 
        max-width: 100%; 
        * { font-size: 20px; }
    }
}
@media screen and (max-width: 1000px) { 
    .ck-about-info-container {
        .ck-about-info { 
            flex-wrap: wrap;
            max-width: 100%; 
            width: 100%;;
            .ck-about-info-text {
                width: 100%;
                font-size: 20px;
            }
            .ck-about-info-image {
                width: 100%;
            }
        }
        .ck-about-image { display: none; }
    }  
}
@media screen and (max-width: 800px) { 
    .ck-about-info { 
        max-width: 100%; 
        p { 
            font-size: 18px;
            margin: 10px 0;
        }
        span { 
            font-size: 18px;
            margin: 0 0 10px 0; 
        }

        .ck-about-info-image {
           display: none;
        }
    }
}
