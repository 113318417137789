@import '../../../../sass/main.scss';

.ck-field {
    @extend .ck-flex-y-start;
    position: relative;
    max-width: 340px;
    width: 100%;
    min-width: 300px;

    .ck-field-label {
       font-size: 16px;
       font-weight: 500;
       width: 100%;
       margin: 0 0 5px 0;
       color: $color-main;
    }

    .ck-input-container {
        display: inline-block;
        position: relative;
        width: 100%;          
        
        .ck-under-input-bground {
            position: absolute;
            height: 48px;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 24px;
            box-sizing: border-box;
        }
        input {
            position: relative;
            width: 100%;
            height: 48px;
            outline: none;
            padding-inline: 0;
            padding-block: 0;
            margin: 0;
            font-size: 20px;
            border-radius: 24px;
            padding: 0 20px;
            border: unset;
            box-sizing: border-box;
            background-color: rgb(desaturate($color-main, 38%), 8%);

            &::placeholder {
                opacity: 0.5;
                color: desaturate($color-main, 90%);
              }

            &.ck-focus {
                background-color: rgb(desaturate($color-main, 38%), 12%);
            }
            &.ck-press { position: relative; }
        }
        .ck-field-indicator {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-color: rgb(mix($color-white, $color-grey, 50%), 50%);
            box-shadow: 0px 8px 14px -6px $color-black;
     
            div {
               position: absolute;
               width: 4px;
               height: 4px;
               left: 6px;
               top: 6px;
               border-radius: 50%;
               background-color: $color-gray;
            }
         }
    }

    .ck-field-info {
        margin: 5px 0 0 0;
        font-size: 14px;
        color: $color-grey;
    }

    &.ck-no-indicate-begin {
        .ck-field-indicator { display: none; }
    }
    &.ck-invalid {
        .ck-field-indicator {
            display: block;
            background-color: rgb(mix($color-white, $color-red, 50%), 50%);
            div { background-color: $color-red; }
        }
    }
    &.ck-valid {
        .ck-field-indicator {
            display: block;
            background-color: rgb(mix($color-white, $color-green, 50%), 50%);
            div { background-color: $color-green; }
        }
    }

    &.ck-disabled {
        opacity: 0.5;
    }
}

@media screen and (max-width: 680px) {
    .ck-field {
        min-width: 240px;
    }
  }