@import '../../../../../sass/main.scss';

.ck-button-wrapper { 
  @extend .ck-flex-x-center;
  min-width: 190px;
  min-height: 44px;

  .ck-button-info {  color: $color-grey; }
}

.ck-button {
  display: inline-block;
  position: relative;
  min-width: 190px;
  height: 46px;
  border-radius: 1px;
  background-color: $color-main;
  transition: background-color 0.25s 0s;
  cursor: pointer;
  
  .ck-button-text { 
    @extend .ck-flex-x-center;
    width: 100%;
    height: 100%;
    padding: 1px 0 0 0;
    box-sizing: border-box;
    text-align: center;
    color: $color-black;
    font-size: 14px;
    font-weight: 600; 
    overflow: hidden; 
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .ck-ico {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 7px;
    top: 50%;
    margin: -12px 0 0 0;
    overflow: hidden;

    * { fill: $color-black; }
  }
  .ck-button-disabler {
    @extend .ck-disabler;
    border-radius: 1px;
  }

  &.ck-icon-right {
    visibility: dvisible;
    right: 6px;
  }

  &.ck-enter:not(.ck-disabled) {
     background-color: lighten($color-main, 8%);
  }
  &.ck-press:not(.ck-disabled) {
    @extend .ck-animate-press-button-default;
  }
  &.ck-disabled {
    cursor: default;
    opacity: 0.5;
    .ck-button-disabler { display: block; }
  }

  &.ck-button-lined, &.ck-button-lined-soft {
    box-sizing: border-box;
    border: 4px solid $color-main;
    background-color: unset;
    transition: border 0.2s 0s;
  
    .ck-button-text { 
      color: $color-main-lighten; 
      transition: color 0.2s 0;
    }
    .ck-ico {
      * { fill: $color-main; }
      div {
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .ck-button-disabler {
      @extend .ck-disabler;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px;
      border-radius: 1px;
    }

    &.ck-enter:not(.ck-disabled) {
      background-color: unset;
      border: 4px solid lighten($color-main-lighten, 7%);

      .ck-button-text { color: lighten($color-main-lighten, 11%) }
   }
   
  }
  &.ck-button-lined-soft {
    background-color: rgb($color-main, 10%);
    transition: background-color 0.25s 0s;
  
    .ck-button-text { color: $color-white; }
  
    &.ck-enter:not(.ck-disabled) {
      background-color: rgb($color-main, 25%);
    }
  }
  &.ck-button-rounded {
    border-radius: 23px;
    .ck-button-disabler { border-radius: 23px; }
    
  }

  /** White color modifycation */
  &.ck-button-color-white {
    &.ck-button-lined-soft {
      &.ck-enter:not(.ck-disabled) { background-color: transparent; }
    }
    &.ck-button-lined-soft, &.ck-button-lined {
      border: 4px solid $color-white;
      transition: all 0.2s 0s;  
      .ck-button-text {
        transition: color 0.2s 0s; 
        color: $color-white; 
      }
      .ck-ico { svg * { fill: $color-white; }}
      .ck-button-disabler { background-color: rgb($color-white, 50%); }
      &.ck-enter:not(.ck-disabled) {
        border: 4px solid mix($color-main, $color-white, 20%);
        .ck-ico { svg * { fill: mix($color-main, $color-white, 20%); }}
        .ck-button-text { color: mix($color-main, $color-white, 20%); }
     }
    }
  }
  /** Second modifycation */
  &.ck-button-color-second {
    background-color: $color-second;
    .ck-button-text { color: $color-main; }
    .ck-ico { svg * { fill: $color-main; }}
    .ck-button-disabler {
       background-color: rgb($color-main, 50%);
    }
  }
}





