@import '../../../../../../sass/main.scss';


.ck-button-special-animated-2 {
  @extend .ck-flex-x-center;
  position: relative;
  min-width: 190px;
  height: 46px;
  border-radius: 1px;
  cursor: pointer;
  
  .ck-button-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-main;
        transition: background-color 0.25s 0s;
        animation: animateSpecialAnimate_1 8s 0s infinite forwards;
        @keyframes animateSpecialAnimate_1 {
          5% { transform: translateX(0); opacity: 1; }
          12% { transform: translateX(100%); }
          12.01% { transform: translateX(-100%); }
          15% { transform: translateX(-5%); }
          25% { transform: translateX(0); opacity: 1; }
        }
    }
  }
  .ck-button-text { 
    @extend .ck-flex-x-center;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1px 0 0 0;
    box-sizing: border-box;
    text-align: center;
    color: $color-black;
    font-size: 14px;
    font-weight: 600; 
    overflow: hidden; 
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .ck-ico-right {
    position: absolute;
    width: 24px;
    height: 24px;
    left: -50%;
    opacity: 0;
    * { fill: $color-main; }
    animation: animateSpecialAnimate_2 8s 0s infinite forwards;
    @keyframes animateSpecialAnimate_2 {
      5% { left: -50%; opacity: 0;}
      10% { left: -30%; opacity: 1;}
      15% { left: 30%; opacity: 0;}
      20% { left: -40%; opacity: 0; }
      24% { left: -20%; opacity: 1; }
      24.1% { opacity: 0; } 25% { opacity: 1; }
      28% { left: 10%; opacity: 0; }
      100% { left: 180%; opacity: 0;}
    }
  }
  .ck-button-disabler {
    @extend .ck-disabler;
    border-radius: 1px;
  }
  &.ck-enter:not(.ck-disabled) {
    .ck-button-background { div { background-color: lighten($color-main, 8%); } } 
  }
  &.ck-press:not(.ck-disabled) { @extend .ck-animate-press-button-default; }
  &.ck-disabled {
    cursor: default;
    opacity: 0.5;
    .ck-button-disabler { display: block; }
  }
  &.ck-button-text-color-white {
    .ck-button-text { color: $color-white; }
  }
}

.ck-button-special-animated-2 {
  .ck-animated-ellipses {
    @extend .ck-flex-x-center;
    position: absolute;
    width: 30px;
    height: 30px;
 
    div {
      position: absolute;
      width: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid $color-main;
      transform-origin: center;
      opacity: 0;
      &:nth-child(1) {
        width: 28%;
        border: none;
        background-color: $color-main;
      }
      &:nth-child(2) { width: 54%; }
      &:nth-child(3) { width: 100%; }
    }
  }
 
  .ck-animated-ellipses:nth-child(1) {
    left: -50%;
    :nth-child(1) { animation: animateSpecialAnimate_3 8s 0s forwards infinite; }
    :nth-child(2) { animation: animateSpecialAnimate_4 8s 0s forwards infinite; }
    :nth-child(3) { animation: animateSpecialAnimate_5 8s 0s forwards infinite; }
    @keyframes animateSpecialAnimate_3 {
      0% { opacity: 0; transform: scale(0.5); } 4% { opacity: 1; transform: scale(1); } 
      4.1% { opacity: 0; } 5% { opacity: 0.2; } 6%{ opacity: 1; transform: scale(1); }
      12% { opacity: 0; transform: scale(0); } 100% { opacity: 0; }
    }
    @keyframes animateSpecialAnimate_4 {
      4% { opacity: 0; } 5% { opacity: 1; } 
      6% { opacity: 0; } 7% { opacity: 1; } 8% { opacity: 1; } 
      18% { opacity: 0;} 100% { opacity: 0; }
    }
    @keyframes animateSpecialAnimate_5 {
      0% { opacity: 0; transform: scale(0.5); } 5% { opacity: 0; } 
      15% { opacity: 1; transform: scale(1); } 35% { opacity: 0; } 
    }
  }
  .ck-animated-ellipses:nth-child(2) {
    left: auto;
    right: -50%;
    :nth-child(1) { animation: animateSpecialAnimate_6 8s 1s forwards infinite; }
    :nth-child(2) { animation: animateSpecialAnimate_7 8s 1s forwards infinite; }
    :nth-child(3) { animation: animateSpecialAnimate_8 8s 1s forwards infinite; }
    @keyframes animateSpecialAnimate_6 {
      0% { opacity: 0; transform: scale(0.5); } 4% { opacity: 1; transform: scale(1); } 
      4.1% { opacity: 0; } 5% { opacity: 0.2; } 6%{ opacity: 1; transform: scale(1); }
      12% { opacity: 0; transform: scale(0); } 100% { opacity: 0; }
    }
    @keyframes animateSpecialAnimate_7 {
      4% { opacity: 0; } 5% { opacity: 1; } 
      6% { opacity: 0; } 7% { opacity: 1; } 8% { opacity: 1; } 
      18% { opacity: 0;} 100% { opacity: 0; }
    }
    @keyframes animateSpecialAnimate_8 {
      0% { opacity: 0; transform: scale(0.5); } 5% { opacity: 0; } 
      15% { opacity: 1; transform: scale(1); } 35% { opacity: 0; } 
    }
  }
  .ck-animated-lines {
    position: absolute;
    width: calc(200% - 30px);
    height: 1px;
    overflow: hidden;

    div {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $color-main;
      opacity: 0;
    }
    :nth-child(1) { animation: animateSpecialAnimate_9 8s 0s forwards infinite; }
    @keyframes animateSpecialAnimate_9 {
      8% { opacity: 0; transform: translateX(-100%); }
      10% { opacity: 1; transform: translateX(-90%); }
      24% { opacity: 1; transform: translateX(96%); } 
      35% { opacity: 1; transform: translateX(100%); } 
      50% { opacity: 0; } 
      100% { opacity: 0; transform: translateX(100%); }
    }
  }
}



